.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.category {
  background-color: #f9f8f8;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
}

.category-header {
  text-align: center;
}

.category-header img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.category-header h2 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.category-header p {
  font-size: 14px;
  color: #666;
}

.subcategories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.subcategory {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: calc(50% - 5px);
}

.subcategory img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.subcategory h3 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.subcategory p {
  font-size: 12px;
  color: #666;
}

.subcategory {
  position: relative;
  cursor: pointer;
}

.subcategory-hover-popup {
  display: none;
  position: absolute;
  left: 100%; /* Position to the right on larger screens */
  top: 0;
  width: 300px; /* Adjust as needed */
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  padding: 15px;
  border-radius: 5px;
  text-align: justify;
  text-justify: inter-word;
}

@media (max-width: 640px) { /* Tailwind's sm breakpoint */
  .subcategory-hover-popup {
    left: 0; /* Adjust for small screens */
    width: 100%; /* Take full width */
  }
}

.subcategory:hover .subcategory-hover-popup {
  display: block;
}